import React from 'react'
import './styles.css'


function Chapter2() {
  return (
    <div className="conta2">
    </div>
  )
}

export default Chapter2