import React from 'react'
import './QA.css'

function QA() {
  return (
    <div className="QAcontainer">
        <h2>Q & A</h2>
    </div>
  )
}

export default QA